import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styles from "./style.module.scss"

const TalksList = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "talk" }}}
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`)
  const talks = allMarkdownRemark.edges
  return (
    talks.map(({ node }) => {
      const title = node.frontmatter.title || node.fields.slug
      return (
        <article className={styles.talkContainer} key={node.fields.slug}>
          <div className={styles.header}>
            <div className={styles.talkTitle}>
              {title}
            </div>
            <small className={styles.date}>{node.frontmatter.date}</small>
          </div>
          <section>
            <div className={styles.talkDescription}>
              {node.frontmatter.description}
            </div>

            <div
              className={styles.talkDescription}
              dangerouslySetInnerHTML={{
                __html: node.html
              }}
            />
          </section>
          <div className={styles.separator}/>
        </article>
      )
    })
  )
}

export default TalksList
