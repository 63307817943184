import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TalksList from "../components/talkslist"

const AboutPage = ({ location }) => {
  return (
    <Layout location={location} title={"Talks and Presentations"}>
      <SEO title="Talks"/>
      <TalksList/>
    </Layout>
  )
}

export default AboutPage
